export const PUBLIC_ENDPOINTS = [
    "login",
    "forgetPassword?username=",
    "validateOtp",
    "generateOtp"
];

export const URLS = {
  login: 'login',
  inProcessList: 'list',
  previousList: 'previous',
  prescriptionDetail: 'detail',
  smsTemplate: 'smsTemplate',
  processRecord: 'processRecord',
  unLockRecord: 'unLock',
  toCallNoResponseList: 'noResponse',
  toCallYesResponseList: 'yesResponse',
  approved:'approved',
  addInsurance: 'addInsurance',
  updateStatus: 'updateStatus',
  pending: 'pending',
  faxReceived: 'faxReceived',
  updateCallCount: 'updateCallCount',
  notes: 'notes',
  addNote: 'addNote'
};