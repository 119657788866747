import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
  ForgotPwd: {}
};


export const forgetPwd = createAsyncThunk("forgetPassword", (username) =>
  httpClientJava
    .get(`forgetPassword?username=` + username)
    .then(({ data }) => {
      return data;
    })
    .catch(error => error)
);


export const forgot_password = createSlice({
  name: "forgot_password",
  initialState,
  // reducers: {

  // },
  reducers: {
    setForgotPasswordData: (state, action) => {
      state.ForgotPwd = action.payload;
    },
    [forgetPwd.pending.type]: (state, action) => {
      state.ForgotPwd = {
        status: "loading",
        data: {},
        error: {},
      };
    },
    [forgetPwd.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
      state.ForgotPwd = {
        status: "idle",
        data: action.payload,
        error: {},
      };
    },
    [forgetPwd.rejected.type]: (state, action) => {
      state.ForgotPwd = {
        status: "idle",
        data: {},
        error: action.payload,
      };
    },
  },
});

export const { setForgotPasswordData } = forgot_password.actions;

export default forgot_password.reducer;