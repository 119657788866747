import { toast } from "react-toastify";
import check from "../../assets/images/RxLink assets/new assets/green check.svg"
import redx from "../../assets/images/RxLink assets/new assets/red x.svg"

export const successToast = (message) => {
    return toast.success(<span role="alert">{message}</span>, {
        // role:"alert",
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
        closeButton: <span className="toast_dismiss">dismiss</span>,
        icon: <img src={check} alt=""/>,
        // 'aria-live': 'assertive',
        // 'aria-label': message,
        // autoClose: 335000,
    });
}

export const errorToast = (message) => {
    return toast.error(<span role="alert">{message}</span>, {
        // role:"alert",
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
        closeButton: <span className="toast_dismiss">dismiss</span>,
        icon: <img src={redx} alt="" />,
        // 'aria-live': 'assertive',
        // 'aria-label': message,
        // autoClose: 335000,
    });
}