import { MotionButton } from './motion/MotionButton'

export const Button = ({
    onClick,
    children,
    variant,
    disabled,
    loading,
    ...props
}) => {
    const buttonClass = variant === "primary" ? "btn_success" : "btn_default";
    return (
      <MotionButton>
        <button
          {...props}
          className={buttonClass}
          onClick={onClick}
          disabled={loading || disabled}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            {loading && <div className="button-loader" />}
            {children}
            {loading && (
              <div className="button-loader" style={{ visibility: 'hidden' }} />
            )}
          </div>
        </button>
      </MotionButton>
    );
}