import axios from 'axios';
import { baseUrlJava, requestHeaderJava } from '../../../environment';
import { errorToast } from '../../../components/toast/toast';
import { PUBLIC_ENDPOINTS } from './constants';

export const apiJava = axios.create({
  baseURL: baseUrlJava,
  headers: requestHeaderJava,
});

// Add a response interceptor to handle responses
apiJava.interceptors.response.use(
  (response) => {
    // Handle successful responses
    const responseData = response.data;
    if (responseData) {
      // Process data here
      return responseData;
    } else if (response.message) {
      errorToast(response.message);
      return undefined;
    }
    // TODO: Delete later on -> For Developer use
    errorToast('Something went wrong');
    return undefined;
  },
  (error) => {
    // Handle errors
    if (error.response && error.response.data) {
      const response = error.response.data;
        if (response.statusCode === 412 || response.statusCode === 401) {
          errorToast('Authorization Error');
          localStorage.clear();
          window.location.href = '/';
          return Promise.reject(error);
        }
    }
    errorToast('Network Error');
    return Promise.reject(error);
  },
);

apiJava.interceptors.request.use(
    config => {
        const publicEndpoints = PUBLIC_ENDPOINTS.includes(config.url);
        if (publicEndpoints) return config;

        const token = JSON.parse(localStorage.getItem('user')).token;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        // Do something with request error
        return Promise.reject(`IRequestCatch: ` + error);
    }
);