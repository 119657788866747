

import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MotionButton } from "../../components/motion/MotionButton";
import { PasswordRegex } from "../../utilities/contants";
import TickCross from "../../utilities/TickCross";
import { useRef } from "react";

const ForgotTempPassword = (props) => {
  const [tempPasswordType, setTempPasswordType] = useState("password");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");

  const oldPasswordRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);



  const changeTempType = () => {
    setTempPasswordType(tempPasswordType == "password" ? "text" : "password");
  };
  const changePasswordType = () => {
    setPasswordType(passwordType == "password" ? "text" : "password");
  };

  const changeConfirmPasswordType = () => {
    setConfirmPasswordType(
      confirmPasswordType == "password" ? "text" : "password"
    );
  };

  const validationSchema = Yup.object().shape({
    tempPassword: Yup.string().required(props.isOldPassword ? "Old password is required" : "Temporary password is required"),

    password1: Yup.string()
      .required("New password is required")
      .min(8, "Password must be at least 8 characters")
      .max(40, "Password must not exceed 40 characters")
      .matches(
        PasswordRegex,
        'Password must be 8 characters long and contain a mix of letters and numbers.'
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password1"), null], "New password does not match"),
  });

  const formik = useFormik({
    initialValues: {
      tempPassword: "",
      password1: "",
      confirmPassword: "",
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (data) => {
      let body = {
        tempPassword: data?.tempPassword,
        password: data?.password1,
        confirmPassword: data?.confirmPassword,
      };
      props.setSubmitResetPasswordData(body);
    },
  });

  const { isSubmitting, isValidating, resetForm } = formik;


  useEffect(() => {
    if (isSubmitting) {
      if (Object.keys(formik.errors).length > 0) {
        const firstErrorField = Object.keys(formik.errors).find(
          (fieldName) => formik.touched[fieldName]
        );
        if (firstErrorField) {
          switch (firstErrorField) {
            case "tempPassword":
              // oldPasswordRef.current && oldPasswordRef.current.focus();
              window.document.getElementById(props.isOldPassword ? "oldPasswordInput" : "temporaryPasswordInput").focus();
              console.log("indes temp")
              break;
            case "password1":
              passwordRef.current && passwordRef.current.focus();
              break;
            case "confirmPassword":
              confirmPasswordRef.current && confirmPasswordRef.current.focus();
              break;
            default:
              break;
          }
        }
      }
    }

  }, [isSubmitting, isValidating]);

  useEffect(() => {
    if (!props.show) {
      resetForm();
    }
  }, [props.show])


  const resetFormFields = () => {
    resetForm();
  };

  const announceErrorMessage = (id, message) => {
    const liveRegion = document.getElementById(id);
    if (liveRegion) {
      liveRegion.innerText = message;
      liveRegion.setAttribute('aria-live', 'assertive');
    }
  };



  const hideErrorMessage = (id, message) => {
    const liveRegion = document.getElementById(id);
    if (liveRegion) {
      liveRegion.setAttribute('aria-live', 'off');
    }
  };

  return (
    <>
      <Modal className="rest-password-modal" show={props.show} onHide={props.close} keyboard={false} centered>
        {/* <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header> */}
        <Modal.Body>
          <section className="forget_section">
            <div className="container">
              <form onSubmit={formik.handleSubmit} id="resetPasswordForm2">
                <div className="forget_box">
                  <div className="row">
                    <div role="alert" className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <p>
                        Reset Password
                      </p>
                      <div className="login_box">
                        <p>{props.isOldPassword ? "Please type your old password and choose a new strong password" : "Please type the temporary password we texted you and choose a new strong password"}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="forget_input_eye form-group">
                        <input
                          type={tempPasswordType}
                          name="tempPassword"
                          className={
                            "form-control forget_input" +
                            (formik.errors.tempPassword && formik.touched.tempPassword
                              ? " is-invalid"
                              : "")
                          }
                          placeholder={props.isOldPassword ? "Old Password" : "Temporary Password"}
                          onChange={formik.handleChange}
                          value={formik.values.tempPassword}
                          inputmode={props.isOldPassword ? "" : "numeric"}
                          autocomplete="one-time-code"
                          id={props.isOldPassword ? "oldPasswordInput" : "temporaryPasswordInput"}
                          aria-label={props.isOldPassword ? "oldPasswordInput" : "temporaryPasswordInput"}
                          ref={oldPasswordRef}
                          onFocus={() => {
                            announceErrorMessage(props.isOldPassword ? "oldPasswordInputError" : "temporaryPasswordInputError", formik?.errors?.tempPassword)
                          }}
                          onBlur={() => hideErrorMessage(props.isOldPassword ? "oldPasswordInputError" : "temporaryPasswordInputError")}
                        />
                        {formik.errors.tempPassword && formik.touched.tempPassword && <div className="invalid-feedback" aria-live="off" id={props.isOldPassword ? "oldPasswordInputError" : "temporaryPasswordInputError"}>
                          {formik.errors.tempPassword && formik.touched.tempPassword
                            ? formik.errors.tempPassword
                            : null}
                        </div>}
                        {TickCross(formik, "tempPassword", "error-circle")}
                        <i><a href="javascript:void(0)" aria-label={tempPasswordType == "password" ? "show Password" : "hide password"}
                          className={tempPasswordType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                          onClick={() => changeTempType()}
                          style={{ top: 4, color: "#77aca2", position: "relative" }}></a></i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="forget_input_eye form-group">
                        <input
                          type={passwordType}
                          name="password1"
                          className={
                            "form-control forget_input" +
                            (formik.errors.password1 && formik.touched.password1
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="New Password"
                          onChange={formik.handleChange}
                          value={formik.values.password1}
                          id={props.isOldPassword ? "resetNewPasswordInput" : "newPasswordInput"}
                          aria-label={props.isOldPassword ? "resetNewPasswordInput" : "newPasswordInput"}
                          ref={passwordRef}
                          onFocus={() => {
                            announceErrorMessage(props.isOldPassword ? "resetNewPasswordInputError" : "newPasswordInputError", formik?.errors?.password1)
                          }}
                          onBlur={() => hideErrorMessage(props.isOldPassword ? "resetNewPasswordInputError" : "newPasswordInputError")}
                        />
                        {formik.errors.password1 && formik.touched.password1 && <div id={props.isOldPassword ? "resetNewPasswordInputError" : "newPasswordInputError"} aria-live="off" className="warn-feedback" style={{ color: formik.errors.password1 && formik.touched.password1 ? "red" : "", textAlign: "left" }}>
                          Password must be 8 characters long and contain a mix of letters and numbers
                        </div>}
                        {/* {formik.errors.password1 && formik.touched.password1 && (
                          <span className="badge bg-danger rounded-pill error-circle">x</span>
                        )} */}

                        {/* <div className="warn-feedback" >
                          of letters, numbers and symbols
                        </div> */}
                        {/* <div className="invalid-feedback">
                          {formik.errors.password1 && formik.touched.password1
                            ? formik.errors.password1
                            : null}
                        </div> */}
                        {TickCross(formik, "password1", "error-circle")}
                        <i><a href="javascript:void(0)" aria-label={passwordType == "password" ? "show Password" : "hide password"}
                          className={passwordType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                          onClick={() => changePasswordType()} style={{ top: 4, color: "#77aca2", position: "relative" }}
                        ></a></i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="forget_input_eye form-group">
                        <input
                          type={confirmPasswordType}
                          name="confirmPassword"
                          className={
                            "form-control forget_input" +
                            (formik.errors.confirmPassword &&
                              formik.touched.confirmPassword
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Confirm Password"
                          onChange={formik.handleChange}
                          value={formik.values.confirmPassword}
                          id={props.isOldPassword ? "resetConfirmPasswordInput" : "confirmPasswordInput"}
                          aria-label={props.isOldPassword ? "resetConfirmPasswordInput" : "confirmPasswordInput"}
                          ref={confirmPasswordRef}
                          onFocus={() => {
                            announceErrorMessage(props.isOldPassword ? "resetConfirmPasswordInputError" : "confirmPasswordInputError", formik?.errors?.confirmPassword)
                          }}
                          onBlur={() => hideErrorMessage(props.isOldPassword ? "resetConfirmPasswordInputError" : "confirmPasswordInputError")}
                        />
                        {formik.errors.confirmPassword &&
                          formik.touched.confirmPassword && <div className="invalid-feedback" aria-live="off" id={props.isOldPassword ? "resetConfirmPasswordInputError" : "confirmPasswordInputError"}>
                            {formik.errors.confirmPassword &&
                              formik.touched.confirmPassword
                              ? formik.errors.confirmPassword
                              : null}
                          </div>}
                        {/* {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                          <span className="badge bg-danger rounded-pill error-circle">x</span>
                        )} */}
                        {TickCross(formik, "confirmPassword", "error-circle")}

                        <i><a href="javascript:void(0)" aria-label={confirmPasswordType == "password" ? "show Password" : "hide password"}
                          className={confirmPasswordType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                          onClick={() => changeConfirmPasswordType()}
                          style={{ top: 4, color: "#77aca2", position: "relative" }}
                        ></a></i>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <MotionButton>
                        <button
                          type="submit"
                          className="btn_success "
                          form="resetPasswordForm2"
                        >
                          Submit
                        </button>
                      </MotionButton>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <MotionButton>
                        <button
                          type="button"
                          className="btn_default"
                          onClick={() => {
                            resetFormFields();
                            props.close();
                          }}
                        >
                          Cancel
                        </button>
                      </MotionButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};


export default ForgotTempPassword;