import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
    PasswordReset : {}
  };


export const resetPassword = createAsyncThunk('PasswordReset', (body) =>
  httpClientJava
    .post(`passwordReset`, body)
    .then(({ data }) => {
      return data;
    })
    .catch((error) => error),
);

export const reset_password = createSlice({
  name: 'reset_password',
  initialState,
  // reducers: {

  // },
  reducers: {
    setResetPasswordData: (state, action) => {
      state.PasswordReset = action.payload;
    },
    [resetPassword.pending.type]: (state, action) => {
      state.PasswordReset = {
        status: 'loading',
        data: {},
        error: {},
      };
    },
    [resetPassword.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
      state.PasswordReset = {
        status: 'idle',
        data: action.payload,
        error: {},
      };
    },
    [resetPassword.rejected.type]: (state, action) => {
      state.PasswordReset = {
        status: 'idle',
        data: {},
        error: action.payload,
      };
    },
  },
});

export const { setResetPasswordData } = reset_password.actions;

export default reset_password.reducer;