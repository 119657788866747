import { useState, useEffect } from 'react'
import { usePostMutation } from '../../../../utilities/API/javaAPIClient/api'
import { useNavigate } from 'react-router-dom';

export const usePrescriptionData = (url) => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'))

    const [data, setData] = useState([])
    const [query, setQuery] = useState({
        appUserSeqNo: user ? user.appUserSeqNo ?? 0 : 0,
        sortBy: "rxDate",
        orderBy: 30,
        searchWord: '',
        pageNo: 1,
        limit: 10
    })

    const { isLoading, mutateAsync } = usePostMutation();

    const fetchPrescriptionData = () => {
        if (!user) return navigate("/");
        try {
            mutateAsync({ url: url, data: query }).then((response) => {
                if (!response || !response.data) return;
                setData(response.data)
            })
        } catch (error) {
            console.log({ error })
        }
    }

    useEffect(() => {
        fetchPrescriptionData()
    }, [query])

    const onQueryChange = (data, type) => {
        if (type === 'search') {
            setQuery({ ...query, searchWord: data })
            return;
        }
        if (type === 'sort') {
            setQuery({ ...query, sortBy: data.columnKey, orderBy: data.order === 'ascend' ? 30 : 40 })
            return;
        }
        if (type === 'page') {
            setQuery({ ...query, pageNo: data.page, limit: data.pageSize })
            return;
        }
        if (type === 'refresh') {
            fetchPrescriptionData()
            return;
        }
    }


    return {
        data,
        isLoading,
        onQueryChange
    }
}