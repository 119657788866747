import { HDivider } from '../../../../components/Divider';
import { ModalWrapper } from '../../../../components/Modal';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostMutation } from '../../../../utilities/API/javaAPIClient/api';
import { URLS } from '../../../../utilities/API/javaAPIClient/constants';
import { Medicenes } from './components/Medicenes';
import { InsuranceInfo, OfferLink, PatientInfo, PharmacyInfo } from './components/PatientInfo';
import { concateStrings, formatDob } from '../../../../utilities/formatters/formatPhoneNumber';
import {
  convertDateStringToFormat,
  convertDateStringtoAPIFormat,
} from '../../../../utilities/date/date';
import { errorToast } from '../../../../components/toast/toast';
import { useGetUser } from '../../../../utilities/useGetUser';
import { useWindowWidth } from '../../../../utilities/hooks/useWindowWidth';
export const ViewPatientModal = ({
  visible,
  setVisible,
  onSuccessfulSubmit,
}) => {
  const [editDateEnabled, setEditDateEnabled] = useState(false);
  const [modalData, setModalData] = useState(undefined);
  const [isCalenderVisible, setIsCalenderVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const [dateError, setDateError] = useState(undefined);
  const user = useGetUser();
  const navigate = useNavigate();
  const {windowWidth} = useWindowWidth()

  const { isLoading: isLoadingModelData, mutateAsync: modelDataMutation } =
    usePostMutation();
  const { isLoading: isSubmitLoading, mutateAsync: submitDataMutatuion } =
    usePostMutation();
  const { isLoading: isCancelLoading, mutateAsync: cancelMutatuion } =
    usePostMutation();

  const onCancel = () => {
    if (!user) navigate('/');
    if (!modalData || !modalData.pharmTransferLockSeqNo)
      return setVisible(undefined);

    const payload = {
      appUserSeqNo: user ? user.appUserSeqNo ?? 0 : 0,
      pharmTransferLockSeqNo: modalData.pharmTransferLockSeqNo ?? 0,
    };

    cancelMutatuion({
      url: URLS.unLockRecord,
      data: payload,
    }).then((response) => {
      setVisible(undefined);
      setEditDateEnabled(false);
    });
  };

  useEffect(() => {
    setDateError(undefined);
    setSelectedDate(undefined);
    setModalData(undefined);
    if (!user) return navigate('/');
    if (!visible) return;

    modelDataMutation({
      url: URLS.prescriptionDetail,
      data: {
        appUserSeqNo: user ? user.appUserSeqNo ?? 0 : 0,
        userSeqNo: visible.userSeqNo ?? 0,
        firstName: visible.firstName ?? '',
        lastName: visible.lastName ?? '',
        rxDate: visible.rxDate ?? '',
        pharmacyName: visible.pharmacyName ?? '',
      },
    }).then((response) => {
      if (!response) return;
      if (response.statusCode !== 200) {
        errorToast(response.message);
        return setVisible(undefined);
      }
      if (!response.data) return;
      setModalData(response.data);
      setSelectedDate({
        date: convertDateStringToFormat(response.data.deliveryDate),
        time: convertDateStringToFormat(response.data.deliveryDate, 'HH:mma'),
      });
    });
  }, [visible]);


  return (
    <>
      <ModalWrapper
        title={`Transfer requested on ${convertDateStringToFormat(
          modalData?.rxDate,
        )}`}
        modelHidden={isCalenderVisible}
        visible={visible}
        setVisible={setVisible}
        closeButton={false}
        saveButton={false}
        onCancel={onCancel}
        isSubmitLoading={isSubmitLoading}
        isCancelLoading={isCancelLoading}
        hiddenScrollbar={windowWidth > 602 ? true :false}
        content={
          <>
            {isLoadingModelData ? (
              <div className="center-loader">
                <div className="button-loader" />
              </div>
            ) : (
              <>
                <Medicenes medicenes={modalData?.medName} />
                <HDivider />
                <PatientInfo
                  patientName={concateStrings([
                    modalData?.firstName,
                    modalData?.lastName,
                  ])}
                  dob={formatDob(modalData?.dob)}
                  phone={modalData?.mobileId}
                  address={modalData?.address}
                />
                <HDivider />
                <PharmacyInfo
                  pharmacyName={modalData?.pharmacyName}
                  fax={modalData?.pharmacyFax}
                  phone={modalData?.pharmacyPhone}
                  address={modalData?.pharmacyAddress}
                  city={modalData?.pharmacyCity}
                  state={modalData?.pharmacyState}
                  zipCode={modalData?.pharmacyPostalCode}
                />
                <HDivider />
                <InsuranceInfo
                  group={modalData?.groupNumber}
                  pcn={modalData?.pcn}
                  id={modalData?.memberId}
                  bin={modalData?.bin}
                />
                <HDivider />
              </>
            )}
          </>
        }
      />
    </>
  );
};