import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../src/assets/css/styles.css";
import Footer from "./components/partials/Footer";
import AutoLogout from "./utilities/AutoLogOut";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnimatePresence } from "framer-motion";
import { RoutesWithAnimation } from "./AnimatedRoute";
import Drawer from "./components/Drawer";
import { httpClientJava } from "./environment";
import { setIP } from "./reducers/global";



function App() {
  const [ownPosition, setOwnPosition] = useState(null);
  const [loading, setLoading] = useState(false);

  const sessionLatitude = window.sessionStorage.getItem("latitude");
  const sessionLongitude = window.sessionStorage.getItem("longitude");
  const sessionPostal = window.sessionStorage.getItem("postalCode");

  const userDetail = JSON.parse(localStorage.getItem("user"));

  const totalSavings = userDetail?.totalSavings || 0;

  const ip = useSelector((state) => state.global.ip);

  const dispatch = useDispatch();

  const setIp = (ip) => {
    dispatch(setIP(ip));
  }


  useEffect(() => {
    if (userDetail?.proxyIpAddress)
      setIp(userDetail.proxyIpAddress)
  }, [userDetail?.proxyIpAddress])

  return (
    <>
      {loading ? <div className="loader"></div> : <div className="appContainer">
        <div className="heading">
        </div>
        <div className="">
          <HashRouter>
            <ToastContainer />
            <AutoLogout>
              <LocationProvider>
                <Drawer totalSavings={totalSavings} />
                <RoutesWithAnimation ownPosition={ownPosition} setOwnPosition={setOwnPosition} setIp={setIp} />
              </LocationProvider>
            </AutoLogout>
          </HashRouter>
        </div>
        <Footer />
      </div>}
    </>
  );
}
export default App;

const LocationProvider = ({ children }) => {
  return <AnimatePresence >{children}</AnimatePresence>;
}
