import { motion } from "framer-motion"
import avatar from "../../../../assets/images/RxLink assets/avatar icon.svg";

export const UserProfile = () => <div className="row">
    <div className="col-sm-12 col-md-12 col-lg-12">
        <div className="medicine_icon medicine_icon_m">
            <div className="">
                <motion.img
                    whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
                    src={avatar}
                    className="img-fluid pointer"
                    id="dropdownMenuButton1"
                    aria-expanded="false"
                    type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
                />
            </div>
        </div>
    </div>
</div>