import { useQuery, useMutation } from 'react-query';
import { apiJava } from './javaAPIClient'; // Import your axios instance

// Function to fetch data using GET request
const fetchData = async (url) => {
    const response = await apiJava.get(url);
    return response;
};

// Custom hook for GET requests
export const useGetQuery = (url) => {
    return useQuery(['getData', url], () => fetchData(url));
};

// Function to perform data mutation using POST request
const postData = async ({ url, data }) => {
    const response = await apiJava.post(url, data);
    return response;
};

// Custom hook for POST requests
export const usePostMutation = () => {
    const mutation = useMutation(({ url, data }) => postData({ url, data }));
    return mutation;
};