import { MotionTextH2 } from "../../../components/motion/MotionText";
import { UserProfile } from "./components/UserProfile";
import { Tabs } from "antd";
import { useTabs } from "../../../utilities/hooks/useTabs";
import { AwaitingFaxPatients } from "./components/AwaitingFax";
import { TransferForApprovalPatients } from "./components/TransferForApproval";
import { FaxReceived } from "./components/FaxReceived";

import { URLS } from "../../../utilities/API/javaAPIClient/constants";
import { usePrescriptionData } from "./hooks/usePrescriptionData";
import { useAfterRenderEffect } from "../../../utilities/hooks/useAfterRenderEffect";

const PerscriptionsTransfers = () => {

    const { tabKey, setTabKey } = useTabs();
    const { isLoading: isPendingListLoading, data: pendingData, onQueryChange: pendingQueryChange } = usePrescriptionData(URLS.pending);
    const { isLoading: approvedListLoading, data: approvedData, onQueryChange: approvedQueryChange } = usePrescriptionData(URLS.approved);
    const { isLoading: isPreviousListLoading, data: previousData, onQueryChange: previousQueryChange } = usePrescriptionData(URLS.faxReceived, 'pharmacyName');


    useAfterRenderEffect(() => {
        if (tabKey === "1") pendingQueryChange({}, 'refresh');
        if(tabKey === "2") approvedQueryChange({}, 'refresh');
        if (tabKey === "3") previousQueryChange({}, 'refresh');

    }, [tabKey]);

    useAfterRenderEffect(()=>{
        console.log({pendingData, approvedData})
    },[pendingData, approvedData])

    if (isPendingListLoading  && approvedListLoading) return <div className="loader" />;

    return (
        <div className="container">
            <UserProfile />
            <MotionTextH2 text="Prescription transfers" style={{ fontWeight: 600 }} />
            <Tabs
                onChange={setTabKey}
                type="line"
                activeKey={tabKey}
                defaultActiveKey="1"
            >
                <Tabs.TabPane tab="Transfers for approval" key="1">
                    <TransferForApprovalPatients
                        data={pendingData && pendingData.result}
                        pageNo={pendingData && pendingData.pageNo}
                        limit={pendingData && pendingData.limit}
                        total={pendingData && pendingData.totalCount}
                        isDataLoading={isPendingListLoading}
                        onQueryChange={pendingQueryChange}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Awaiting fax" key="2">
                    <AwaitingFaxPatients
                        data={approvedData && approvedData.result}
                        pageNo={approvedData && approvedData.pageNo}
                        limit={approvedData && approvedData.limit}
                        total={approvedData && approvedData.totalCount}
                        isDataLoading={approvedListLoading}
                        onQueryChange={approvedQueryChange}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Fax received" key="3">
                    <FaxReceived
                        data={previousData && previousData.result}
                        pageNo={previousData && previousData.pageNo}
                        limit={previousData && previousData.limit}
                        total={previousData && previousData.totalCount}
                        isDataLoading={isPreviousListLoading}
                        onQueryChange={previousQueryChange}
                    />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
}





export default PerscriptionsTransfers;