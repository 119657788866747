export const Medicenes = ({ medicenes }) => {
    const displayMedicenes = medicenes ? medicenes.split(',').map(item => item.trim()) : [];
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: '10px', fontSize: '11px', fontWeight: 400, color: '000000E5' }}>
            <span style={{ fontWeight: 600 }}>ORDER</span>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                {displayMedicenes.map((item, index) => <span key={`med-${index}`}><span style={{ fontWeight: 900 }}>&#x2022;</span>{' '}{item}</span>)}
            </div>
        </div>
    )
}