import { SearchPatient } from "./SearchPatient"
import { AppPagination, AppTable } from "../../../../components/Table"
import { usePrescriptionColumns } from "../hooks/usePrescriptionColumns"
import { useEffect, useState } from "react"
import { ViewPatientModal } from "../../Modals/ViewPatientModal/ViewPatientModal"
import { POPOVER_CONTENT, TRANSFER_FOR_APPROVAL_COLUMNS } from "../content/TransferForApprovalColumns"
import { AddInsuranceModal } from "../../Modals/AddInsuranceModal/AddInsuranceModal"
import { NotesModal } from "../../Modals/NotesModal/NotesModal"
import { usePostMutation } from "../../../../utilities/API/javaAPIClient/api"
import { URLS } from "../../../../utilities/API/javaAPIClient/constants"
import { successToast, errorToast } from "../../../../components/toast/toast"
import { useGetUser } from "../../../../utilities/useGetUser";
import { useNotesData } from "../../../../utilities/hooks/useAddNotes"

export const TransferForApprovalPatients = ({
    data = [],
    onQueryChange,
    pageNo,
    limit,
    total,
    isDataLoading,
}) => {
    const { columns, initiateColumnAction } = usePrescriptionColumns(POPOVER_CONTENT, TRANSFER_FOR_APPROVAL_COLUMNS);
    const [isViewPatientModalVisible, setIsViewPatientModalVisible] = useState(undefined);
    const [isNotesModalVisible, setNotesModalVisible] = useState(undefined);

    const { notesData, getNotesLoading } = useNotesData(data, isNotesModalVisible);

    const { isLoading: okToTransferLoading, mutateAsync: okToTransferMutation } = usePostMutation();
    const { isLoading: rejectTransferLoading, mutateAsync: rejectTransferMutation } = usePostMutation();

    const user = useGetUser();

    useEffect(() => {
        if (!initiateColumnAction.status || !initiateColumnAction.record) return;
        if (initiateColumnAction.status === 'view') setIsViewPatientModalVisible(initiateColumnAction.record);
        if (initiateColumnAction.status === 'notes') setNotesModalVisible(initiateColumnAction.record);

        if (initiateColumnAction.status === 'okToTransfer') {
            let body ={
                appUserSeqNo: user ? user.appUserSeqNo ?? 0 : 0,
                userSeqNo: initiateColumnAction.record.userSeqNo ?? 0,
                firstName: initiateColumnAction.record.firstName ?? '',
                lastName: initiateColumnAction.record.lastName ?? '',
                rxDate: initiateColumnAction.record.rxDate ?? '',
                pharmacyName: initiateColumnAction.record.pharmacyName ?? '',
                transferStatus: 'Transfer_Approved'
            }
            try {
                okToTransferMutation({ url: URLS.updateStatus, data: body }).then((response) => {
                    console.log({response})
                    if (!response) return;
                    if (response.statusCode === 200) {
                      successToast('Transfer approved');
                      onQueryChange({},'refresh');
                    } else {
                      errorToast(response.message);
                    }
                });
              } catch (error) {
                console.log({ error })
              }
        }

        if (initiateColumnAction.status === 'cannotTransfer') {
            let body ={
                appUserSeqNo: user ? user.appUserSeqNo ?? 0 : 0,
                userSeqNo: initiateColumnAction.record.userSeqNo ?? 0,
                firstName: initiateColumnAction.record.firstName ?? '',
                lastName: initiateColumnAction.record.lastName ?? '',
                rxDate: initiateColumnAction.record.rxDate ?? '',
                pharmacyName: initiateColumnAction.record.pharmacyName ?? '',
                transferStatus: 'Transfer_Rejected'
            }
            try {
                rejectTransferMutation({ url: URLS.updateStatus, data: body }).then((response) => {
                    console.log({response})
                    if (!response) return;
                    if (response.statusCode === 200) {
                      successToast("Transfer rejected");
                      onQueryChange({},'refresh');
                    } else {
                      errorToast(response.message);
                    }
                });
              } catch (error) {
                console.log({ error })
              }
        }

        
    }, [initiateColumnAction])

    return (
        <>
            <SearchPatient
                onSearchChange={(value) => onQueryChange(value, 'search')}
            />
            {notesData.length > 0 ? (
                <div className="table-container">
                    <div className="table-container-inner">
                        <AppTable
                            columns={columns}
                            dataSource={notesData ?? []}
                            onChange={(pagination, filter, sort) =>
                                onQueryChange(sort, 'sort')
                            }
                            pagination={false}
                            dataLoading={isDataLoading || getNotesLoading}
                        />
                        <AppPagination
                            current={pageNo ?? 1}
                            pageSize={limit ?? 10}
                            total={total ?? 0}
                            onChange={(page, pageSize) =>
                                onQueryChange({ page, pageSize }, 'page')
                            }
                        />
                    </div>
                </div>
            ) : (
                <div className="no-data-message-container">
                    <span className="no-data-message">
                        No transfers are currently queued up
                    </span>
                </div>
            )}
            <ViewPatientModal
                visible={isViewPatientModalVisible}
                setVisible={setIsViewPatientModalVisible}
                onSuccessfulSubmit={() => onQueryChange({}, 'refresh')}
            />
            <NotesModal
                visible={isNotesModalVisible}
                setVisible={setNotesModalVisible}
                onSuccessfulSubmit={() => onQueryChange({}, 'refresh')} />
        </>
    );
}