
import { Table, Pagination } from "antd"
export const AppTable = ({ dataLoading = false, ...props }) => {
    return (
        <Table
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' : 'table-row-light'}
            pagination={{ position: ["bottomleft"] }}
            {...props}
            loading={{ indicator: <div className="button-loader" />, spinning: dataLoading }}
        />
    );
}

export const AppPagination = ({ ...props }) => <Pagination
    {...props}
    pageSizeOptions={[5, 10, 20, 50]}
    defaultCurrent={1}
    defaultPageSize={10}
    showSizeChanger
/>



