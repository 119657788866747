import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PasswordRegex } from "../../utilities/contants";
import TickCross from "../../utilities/TickCross";
import { MotionButton } from "../../components/motion/MotionButton";

const ResetPassword = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(props.show);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [isOpenResetPwdModal, setIsOpenResetPwdModal] = useState(false);

  useEffect(() => {
    // console.log(modalIsOpen);
  }, [modalIsOpen]);

  const changePasswordType = () => {
    setPasswordType(passwordType == "password" ? "text" : "password");
  };

  const changeConfirmPasswordType = () => {
    setConfirmPasswordType(
      confirmPasswordType == "password" ? "text" : "password"
    );
  };

  const validationSchema = Yup.object().shape({

    password1: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(40, "Password must not exceed 40 characters")
      .matches(
        PasswordRegex,
        'Password must be 8 characters long and contain a mix of letters and numbers.'
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password1"), null], "Confirm Password does not match"),
  });

  const formik = useFormik({
    initialValues: {
      password1: "",
      confirmPassword: "",
    },
    validationSchema,
    // validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: (data) => {
      let body = {
        password: data?.password1,
        confirmPassword: data?.confirmPassword,
      };
      props.setSubmitResetPasswordData(body);
    },
  });

  const setResetPwd = (value) => {
    setIsOpenResetPwdModal(value);
  }

  return (
    <>
      <Modal show={props.show} onHide={() => props.close()} keyboard={false} centered>
        {/* <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header> */}
        <Modal.Body>
          <section className="forget_section">
            <div className="container">
              <form onSubmit={formik.handleSubmit} id="resetPasswordForm3">
                <div className="forget_box">
                  <div role="alert" className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <p>
                        Reset Password
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="forget_input_eye form-group">
                        <input
                          type={passwordType}
                          name="password1"
                          className={
                            "form-control forget_input" +
                            (formik.errors.password1 && formik.touched.password1
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="New Password"
                          onChange={formik.handleChange}
                          value={formik.values.password1}
                          id="resetPasswordInput1"
                          aria-label="resetPasswordInput1"
                        />
                        <div role="alert" aria-atomic="true" aria-live="assertive" className="warn-feedback" style={{ color: formik.errors.password1 && formik.touched.password1 ? "red" : "", textAlign: "left" }}>
                          Password must be 8 characters long and contain a mix of letters and numbers
                        </div>
                        {/* {formik.errors.password1 && formik.touched.password1 && (
                          <span className="badge bg-danger rounded-pill error-circle">x</span>
                        )} */}
                        {TickCross(formik, "password1", "error-circle")}

                        {/* <div className="invalid-feedback">
                          {formik.errors.password1 && formik.touched.password1
                            ? formik.errors.password1
                            : null}
                        </div> */}
                        <i><a href="javascript:void(0)" aria-label={passwordType == "password" ? "show Password" : "hide password"}
                          className={passwordType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                          onClick={() => changePasswordType()}
                          style={{ top: 4, color: "#77aca2", position: "relative" }}></a></i>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="forget_input_eye form-group">
                        <input
                          type={confirmPasswordType}
                          name="confirmPassword"
                          className={
                            "form-control forget_input" +
                            (formik.errors.confirmPassword &&
                              formik.touched.confirmPassword
                              ? " is-invalid"
                              : "")
                          }
                          placeholder="Confirm Password"
                          onChange={formik.handleChange}
                          value={formik.values.confirmPassword}
                          id="resetConfirmPasswordInput1"
                          aria-label="resetConfirmPasswordInput1"
                        />
                        <div className="invalid-feedback" role="alert">
                          {formik.errors.confirmPassword &&
                            formik.touched.confirmPassword
                            ? formik.errors.confirmPassword
                            : null}
                        </div>
                        {/* {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                          <span className="badge bg-danger rounded-pill error-circle">x</span>
                        )}
                        {!formik.errors.confirmPassword && formik.touched.confirmPassword && (
                          <span className="badge bg-success rounded-pill error-circle">✔</span>
                        )} */}
                        {TickCross(formik, "confirmPassword", "error-circle")}

                        <i><a href="javascript:void(0)" aria-label={passwordType == "password" ? "show Password" : "hide password"}
                          className={passwordType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                          onClick={() => changeConfirmPasswordType()}
                          style={{ top: 4, color: "#77aca2", position: "relative" }}></a></i>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <MotionButton>
                        <button
                          type="submit"
                          className="btn_success "
                          form="resetPasswordForm3" onClick={() => setResetPwd(true)}
                        >
                          Submit
                        </button>
                      </MotionButton>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <MotionButton>
                        <button
                          type="button"
                          className="btn_default "
                          onClick={() => props.close()}
                        >
                          Cancel
                        </button>
                      </MotionButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" >
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
};


export default ResetPassword;