import { Tooltip as AntdTooltip } from "antd"


export const Tooltip = ({ text }) => {
    return (
        <AntdTooltip title={text} placement="topLeft" arrow={true} color='#fafdff'>
            <div className="tooltip-div">
                {text}
            </div>
        </AntdTooltip>
    )
}