import dayjs from 'dayjs';

export const convertDateStringToFormat = (
    date,
    format = "MM/DD/YYYY",
) => {
    if (!date) return "";
    const fromattedDate = dayjs(date).format(format);
    if (fromattedDate === "Invalid Date") return "";
    return fromattedDate;
}

export const convertDateStringtoAPIFormat = (date) => {
    if (!date) return "";
    const parsedDate = dayjs(date, 'MM/DD/YYYY').set('hour', 8).set('minute', 0).set('second', 0);
    return parsedDate.format("YYYY-MM-DDTHH:mm:ss");
}

export const formatDateOnlyToLocal = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};

export const formatDateOnly = (dateString) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'America/Chicago' };
    return date.toLocaleDateString('en-US', options);
};

export const formatTimeToLocal = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutesStr + ' ' + ampm;
    return strTime;
};

export const formatTime = (dateString) => {
    const date = new Date(dateString);

    const options = { timeZone: 'America/Chicago', hour: '2-digit', minute: '2-digit', hour12: true };
    const timeString = date.toLocaleTimeString('en-US', options);

    return timeString + " CT";
};