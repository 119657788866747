import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava, httpClientJavaRxSense } from "../environment";

const initialState = {
    memberInfo: {},
    rxSenseCardInfo: null,
    hippoCardInfo: null,
    connectiveCards: []
};

export const membersAssign = createAsyncThunk("membersAssign", (body) =>

    httpClientJava
        .post(`members/assign`, body)
        .then(({ data }) => {
            return data;
        })
        .catch(error => error)
);

export const createRxSenseCard = createAsyncThunk("createRxSenseCard", (body) =>

    httpClientJavaRxSense
        .post(`rxSense/createRxSenseCard`, body)
        .then(({ data }) => {
            return data;
        })
        .catch(error => error)
);

export const createConnectiveRxCards = createAsyncThunk("generateConnectiveRx", (body) =>

    httpClientJavaRxSense
        .post(`generateConnectiveRx`, body)
        .then(({ data }) => {
            return data;
        })
        .catch(error => error)
);


export const membersAssignAPI = createSlice({
    name: "membersAssignAPI",
    initialState,
    // reducers: {

    // },
    reducers: {
        setMembersAssign: (state, action) => {
            state.memberInfo = action.payload;
        },
        setRxSenseCardInfo: (state, action) => {
            state.rxSenseCardInfo = action.payload;
        },
        setHippoCardInfo: (state, action) => {
            state.hippoCardInfo = action.payload;
        },
        setConnectiveCards: (state, action) => {
            state.connectiveCards = action.payload;
        },
        [membersAssign.pending.type]: (state, action) => {
            state.memberInfo = {
                status: "loading",
                data: {},
                error: {},
            };
        },
        [membersAssign.fulfilled.type]: (state, action) => {
            //console.log(action.payload);
            state.memberInfo = {
                status: "idle",
                data: action.payload,
                error: {},
            };
        },
        [membersAssign.rejected.type]: (state, action) => {
            state.memberInfo = {
                status: "idle",
                data: {},
                error: action.payload,
            };
        },

        [createRxSenseCard.pending.type]: (state, action) => {
            state.rxSenseCardInfo = {
                status: "loading",
                data: {},
                error: {},
            };
        },
        [createRxSenseCard.fulfilled.type]: (state, action) => {
            console.log(action.payload);
            state.rxSenseCardInfo = {
                status: "fulfilled",
                data: action.payload,
                error: {},
            };
        },
        [createRxSenseCard.rejected.type]: (state, action) => {
            state.rxSenseCardInfo = {
                status: "idle",
                data: {},
                error: action.payload,
            };
        },
    },
});

export const { setMembersAssign, setRxSenseCardInfo, setHippoCardInfo, setConnectiveCards } = membersAssignAPI.actions;

export default membersAssignAPI.reducer;