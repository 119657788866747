export const HDivider = ({ ...props }) => {
    return (
        <div className='section-divider' {...props}></div>
    )
}

export const VDivider = ({ ...props }) => {
    return (
        <div className='section-divider-vertical' {...props}></div>
    )
}