import { useState, useEffect } from "react";
import { useWindowWidth } from "../../../../utilities/hooks/useWindowWidth";

export const usePrescriptionColumns = (popoverContent, prescriptionColumns) => {

    const [initiateColumnAction, setInitiateColumnAction] = useState({
        record: {},
        status: ''
    });

    const [columns, setColumns] = useState(prescriptionColumns(setInitiateColumnAction));
    const { windowWidth } = useWindowWidth();

    useEffect(() => {
        if (windowWidth > 1050) {
            setColumns(prev => {
                return prev.filter(column => column.key !== 'action_popover')
            })

        }
        else {
            if (columns.find((col) => col.key === 'action_popover')) return
            setColumns(prev => {
                return [...prev, popoverContent(setInitiateColumnAction)]
            })
        }

    }, [windowWidth])

    return { columns, initiateColumnAction }
}