import React from 'react'
import { Input } from 'antd';
import { formatDateOnly, formatTime } from '../../../../../utilities/date/date';
import { Tooltip } from '../../../../../components/Tooltip';
import { VDivider } from '../../../../../components/Divider';

const { TextArea } = Input;

function NotesList({ formik, notes=[] }) {
    console.log({ notes });
    const user = JSON.parse(localStorage.getItem('user'))

    return (
        <>
            <div className='notesList'>
                <form id="addNote" style={{ marginBottom: 6 }}>
                    <div className=" col-sm-12 col-md-12 col-lg-12 form-group">
                        <BreadCrumb
                            name={user?.username}
                            date={formatDateOnly(new Date())}
                            time={formatTime(new Date())}
                            styleClass="breadCrumb"
                        />
                        <TextArea
                            placeholder="Enter a new note"
                            autoSize={{ minRows: 2, maxRows: 4 }}
                            name="addNote"
                            onChange={formik.handleChange}
                            value={formik.values.addNote}
                            maxLength={100}
                            style={{ paddingTop: 24, background: "#fff", marginTop: -18 }}
                        />
                    </div>
                </form>
                {notes.map((note, index) => {
                    return (
                        <>
                            <div className="col-sm-12 col-md-12 col-lg-12 form-group">
                                <div className={user?.domainName === note.from ? 'notesItem' : 'notesItemUnread'}>
                                    <BreadCrumb
                                        data={note}
                                        name={note?.userName}
                                        date={formatDateOnly(note?.createdDateTime +"Z")}
                                        time={formatTime(note?.createdDateTime + "Z")}
                                        styleClass="noteData"
                                    />
                                    <div style={{fontSize: 12, paddingLeft: 12, paddingTop: 4, paddingRight: 12, paddingBottom: 6}}>
                                        {note?.note}
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })}
            </div>
        </>
    )
}

export default NotesList;

const BreadCrumb = ({ name, date, time, styleClass }) => {
    console.log({ name, date })

    return (
        <div className={styleClass}>
            <span className="inputText" style={{}}>{<Tooltip text={name} />}</span>
            <span className="inputText" style={{ paddingLeft: 8, paddingRight: 8 }}>{date}</span>
            <span style={{ paddingLeft: 8 }}>{time}</span>
        </div>
    )
}


