import logo from "../assets/images/logo.png"; // for Rxlink
import upennLogo from "../assets/images/PennMedicine.png"; // for PennMedicine
import papajohnsLogo from "../assets/images/papajones-logo.png"; // for papaJohns
import tripmentLogo from "../assets/images/tripment.png"; // for tripment]
import houstonLogo from "../assets/images/houston-health-logo.png"; // for houston-health
import lcmchealthlogo from "../assets/images/LCMC_Health_Logo.png"; // for lcmc
import chnwLogo from "../assets/images/community_health_logo.png";// for community health network
import nclogo from "../assets/images/Norton_clark.png";
import dmhlogo from "../assets/images/decaturmorgan.png";

export function getDomainName() {

    const isUpenn = window.location.href.includes("upenn");
    const isPapaJohn = window.location.href.includes("papajohns") || window.location.href.includes("pju");
    const isTripment = window.location.href.includes("tripment");
    const isHouston = window.location.href.includes("hhc");
    const isLcmc = window.location.href.includes("lcmc");
    const isNC = window.location.href.includes("clark");
    const isCHNW = window.location.href.includes("chnw");
    const isDecaturMorgan = window.location.href.includes("decaturmorganhospital");

    const domainName = isUpenn ? "Upenn" : isPapaJohn ? "PapaJohns" : isTripment ? "Tripment" : isHouston ? "HHC" : isLcmc ? "LCMC" : isNC ? "Clark" : isCHNW ? "CHNW" : isDecaturMorgan ? "DMH" : "RxLink";

    const pid = isUpenn ? "UPENN" : isPapaJohn ? "PAPAJOHNS" : isHouston ? "HHC" : isTripment ? "TRIPMENT" : isLcmc ? "LCMC" : isNC ? "NCH" : isCHNW ? "CHNW" : isDecaturMorgan ? "DMH" : "RXLINK";

    const defaultLogo = isUpenn ? upennLogo : isPapaJohn ? papajohnsLogo : isHouston ? houstonLogo : isTripment ? tripmentLogo : isLcmc ? lcmchealthlogo : isNC ? nclogo : isCHNW ? chnwLogo : isDecaturMorgan ? dmhlogo : logo;

    const healthSystem = isUpenn ? "Upenn Medicine" :
        isPapaJohn ? "Papa John's United" :
            isTripment ? "Tripment Health" :
                isHouston ? "Housten Health System" :
                    isLcmc ? "LCMC Health" : isNC ? "Norton Clark" : isCHNW ? "Community Health Network" :
                        isDecaturMorgan ? "Decatur Morgan Hospital" :
                            "RxLink";
    const altText = isUpenn ? "Upenn Medicine Logo" :
        isPapaJohn ? "Papa John's United Logo" :
            isTripment ? "Tripment Health Logo" :
                isHouston ? "Housten Health System Logo" :
                    isLcmc ? "LCMC Health Logo" : isNC ? "Norton Clark Logo" : isCHNW ? "Community Health Network Logo" :
                        isDecaturMorgan ? "Decatur Morgan Hospital Logo" :
                            "RxLink Logo";

    return {
        pid,
        domainName,
        defaultLogo,
        healthSystem,
        altText
    };
}