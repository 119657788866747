import { useState, useEffect } from "react";
import { usePostMutation } from "../API/javaAPIClient/api";
import { URLS } from "../API/javaAPIClient/constants";
import { errorToast, successToast } from "../../components/toast/toast";

export const useNotesData = (data, isNotesModalVisible) => {

  const [notesData, setNotesData] = useState([]);

  const { isLoading: getNotesLoading, mutateAsync: getNotes } = usePostMutation();

  const user = JSON.parse(localStorage.getItem('user'));
  useEffect(() => {
    if (data?.length > 0) {
      let filteredData = data.filter((item) => item.userSeqNo);
      const fetchNotes = async () => {
        const newNotesData = await Promise.all(filteredData.map(async (item) => {
          item.rxDate = item?.rxDate;
          item.rxDate = item?.rxDate?.substring(0, 10);
          const body = {
            appUserSeqNo: user ? user.appUserSeqNo ?? 0 : 0,
            userSeqNo: item?.userSeqNo ?? 0,
            firstName: item?.firstName ?? '',
            lastName: item?.lastName ?? '',
            rxDate: item?.rxDate ?? '',
            pharmacyName: item?.pharmacyName ?? '',
          };
          try {
            const response = await getNotes({ url: URLS.notes, data: body });
            if (!response) return null;
            if (response.statusCode === 200) {
              return {
                ...item,
                notes: response.data.sort((a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime)),
              };
            } else {
              // errorToast(response.message);
              return null;
            }
          } catch (error) {
            console.log({ error });
            return null;
          }
        }));

        // Filter out null responses and update state
        const filteredNotesData = newNotesData?.filter(Boolean);
        setNotesData(filteredNotesData?.length > 0 ? filteredNotesData : data);
      };

      fetchNotes();
    }
    else {
      setNotesData([]);
    }
  }, [data]);

  useEffect(() => {
    if (isNotesModalVisible && notesData?.length > 0) {
      let newNotes = notesData.map((note) => {
        return {
          ...note,
          isRead: 1
        }
      });
      setNotesData(newNotes)
    }
  }, [isNotesModalVisible])

  return { notesData, getNotesLoading }
}