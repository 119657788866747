import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {
  validateOTP,
  generateOtp,
  otpExpiredApi,
} from "../../reducers/validateOtp";
import Vector from "../../assets/images/tab-otp.svg";
import { motion } from "framer-motion";
import { MotionButton } from "../../components/motion/MotionButton";
import { successToast, errorToast } from "../../components/toast/toast";
import { isAndroid } from "react-device-detect";
import { StringToHTML } from "../../utilities/StringToHtml";
import cross from "../../assets/images/cross.png";
import tick from "../../assets/images/check.png";

const NumberVerification = ({ setIp }) => {
  const [insurance, setInsurance] = useState("");
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inputOTP, setInputOTP] = useState("");
  const [ssn_1, setSsn_1] = useState("");
  const [ssn_2, setSsn_2] = useState("");
  const [ssn_3, setSsn_3] = useState("");
  const [ssn_4, setSsn_4] = useState("");
  const [ssn_5, setSsn_5] = useState("");
  const [ssn_6, setSsn_6] = useState("");

  const [firstLoad, setFirstLoad] = useState(true);

  const [otpError, setOtpError] = useState("**");
  const userBody = JSON.parse(localStorage.getItem("user"));
  const [myMessage, setMessage] = useState("MMMMM");

  useEffect(() => {
    // alert("OTP Expired Resent again ?")
    // call function after every 10 min
    setInterval(otpExpired, 600000);
    // call function once after 10 min
    setTimeout(function () {
      otpExpired();
    }, 600000);
  }, []);

  useEffect(() => {
    let concatenate = ssn_1 + ssn_2 + ssn_3 + ssn_4 + ssn_5 + ssn_6;
    if (!firstLoad) {
      setFirstLoad(false)
      if (concatenate.length < 6) {
        setOtpError(true);
      }
      if (concatenate.length == 6) {
        setOtpError(false);
      }
    }
    if (firstLoad) {
      setFirstLoad(false)
    }
    // debugger

  }, [ssn_1, ssn_2, ssn_3, ssn_4, ssn_5, ssn_6])

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    if (name === "ssn-1") {
      setSsn_1(value, () => {
        let concatenate = ssn_1 + ssn_2 + ssn_3 + ssn_4 + ssn_5 + ssn_6;

      });
    }
    if (name === "ssn-2") {
      setSsn_2(value);
    }
    if (name === "ssn-3") {
      setSsn_3(value);
    }
    if (name === "ssn-4") {
      setSsn_4(value);
    }
    if (name === "ssn-5") {
      setSsn_5(value);
    }
    if (name === "ssn-6") {
      setSsn_6(value);
    }


    // let concatenate = ssn_1 + ssn_2  + ssn_3  + ssn_4  + ssn_5  + ssn_6;
    // setInputOTP(concatenate);
    const [fieldName, fieldIndex] = name.split("-");
    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 6) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=ssn-${parseInt(fieldIndex, 10) + 1}]`
        );
        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  const resetOtp = () => {
    // document.querySelector(`input[name=ssn-1]`).value = "";
    // document.querySelector(`input[name=ssn-2]`).value = "";
    // document.querySelector(`input[name=ssn-3]`).value = "";
    // document.querySelector(`input[name=ssn-4]`).value = "";
    // document.querySelector(`input[name=ssn-5]`).value = "";
    // document.querySelector(`input[name=ssn-6]`).value = "";
    setSsn_1('');
    setSsn_2('');
    setSsn_3('');
    setSsn_4('');
    setSsn_5('');
    setSsn_6('');
  };

  const validateOtp = () => {
    let concatenate = ssn_1 + ssn_2 + ssn_3 + ssn_4 + ssn_5 + ssn_6;

    const userBody = JSON.parse(localStorage.getItem("user"));
    if (concatenate.length == 0) {
      // addToast("OTP is required", {
      //   appearance: "error",
      //   autoDismiss: 5000,
      // });
      errorToast("OTP is required");
      setOtpError(true);

      return;
    }
    if (concatenate.length < 6) {
      // addToast("Valid OTP is required", {
      //   appearance: "error",
      //   autoDismiss: 5000,
      // });
      errorToast("Valid OTP is required");
      setOtpError(true);

      return;
    }
    let body = {
      username: userBody?.username,
      otp: concatenate,
    };
    setLoading(true);
    dispatch(validateOTP(body))
      .unwrap()
      .then((response) => {
        setLoading(false);
        console.log(response);

        if (response.statusCode == 200) {
          successToast("OTP validated.");
          localStorage.setItem("user", JSON.stringify(response?.data));
          setIp(response?.data.proxyIpAddress)
          navigate("/prescriptionTransfers");

        } else {
          errorToast(<StringToHTML htmlString={response?.message} />);
          setSsn_1("");
          setSsn_2("");
          setSsn_3("");
          setSsn_4("");
          setSsn_5("");
          setSsn_6("");
          resetOtp();
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const resendOtp = () => {
    resetOtp();
    setLoading(true);
    let body = {
      username: userBody?.username,
    };
    dispatch(generateOtp(body))
      .unwrap()
      .then((response) => {
        // window.location.reload();
        setInputOTP(null);
        console.log(response);
        setLoading(false);
        if (response.message) {
          // addToast(response?.message, {
          //   appearance: "success",
          //   autoDismiss: 5000,
          // });
          successToast(<StringToHTML htmlString={response?.message} />);
        }
        if (response?.statusCode >= 200 && response?.statusCode <= 299) {
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const otpExpired = () => {
    setLoading(true);

    let body = {
      username: userBody?.username,
    };
    dispatch(otpExpiredApi(body))
      .unwrap()
      .then((response) => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <>
      <section className="fadeAni ">
        <div className="row g-0">
          <div className="col-sm-12 col-md-12 col-lg-12 ">
            <div className="">&nbsp;</div>
          </div>
        </div>
        <div className="row margintop10 g-0">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0 autoMargin">
            <div className="vector-image">
              <motion.img
                key={Vector}
                // src={image.src}
                initial={{ opacity: 0, x: 500, }}
                animate={{ opacity: 1, x: 0, }}
                exit={{ opacity: 0 }}
                // src={tabHealth}
                transition={{ duration: 0.8, type: 'tween', }}
                src={Vector} className="img-fluid" />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 paddingZero" style={{ zIndex: 100 }}>
            <div className="" >
              <motion.div className="insurance_inner_main"
                initial={{ scale: 0.8, }}
                animate={{ scale: 1 }}
                transition={{ duration: .5 }}
              >
                <form id="otpID">
                  <div className="row" id="cc-info-div">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <motion.div className="insurance_inner"
                        initial={{ opacity: 0, }}
                        animate={{ opacity: 1, }}
                        transition={{ duration: 0.7 }}
                      >
                        <div role="alert">
                          <motion.div
                            className="row"
                            initial={{ x: 300, }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.8 }}
                          >
                            <div className="col-12 col-sm-12 col-md-12 col-lg-10">
                              <h1>Let’s verify your email</h1>
                            </div>
                          </motion.div>
                          <motion.div className="row"
                            initial={{ x: 300, }}
                            animate={{ x: 0 }}
                            transition={{ duration: 0.8, delay: 0.15 }}
                          >
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                              <p>
                                Enter the 6 digit code we just sent you via email. After verification, you’ll be able to use your e-mail to access your account.
                              </p>
                            </div>
                          </motion.div>
                        </div>
                        <motion.div className="row"
                          initial={{ x: 300, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          transition={{ duration: 0.8, delay: 0.25 }}
                        >
                          <div className={!isAndroid ? "col-12 col-sm-12 col-md-12 col-lg-12" : "col-6 col-sm-6 col-md-6 col-lg-6"}>
                            <div className="numberVerify forget_input_eye">
                              <input
                                type="number"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                className="form-control  custom-form-control"
                                placeholder={isAndroid ? "" : "0"}
                                name="ssn-1"
                                value={ssn_1}
                                // maxLength={1}
                                onChange={(e) => {
                                  if (e.currentTarget.value.length < 2 || isAndroid)
                                    handleChange(e);
                                }}
                                autocomplete="one-time-code"
                              // max={9}
                              />
                              {!isAndroid && <>
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  inputmode="numeric"
                                  className="form-control  custom-form-control"
                                  placeholder="0"
                                  name="ssn-2"
                                  value={ssn_2}
                                  maxLength={1}
                                  onChange={(e) => {
                                    if (e.currentTarget.value.length < 2)
                                      handleChange(e);
                                  }}
                                  autocomplete="one-time-code"
                                  max={9}
                                />
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  inputmode="numeric"
                                  className="form-control  custom-form-control"
                                  placeholder="0"
                                  name="ssn-3"
                                  value={ssn_3}
                                  maxLength={1}
                                  // onChange={handleChange}
                                  onChange={(e) => {
                                    if (e.currentTarget.value.length < 2)
                                      handleChange(e);
                                  }}
                                  autocomplete="one-time-code"
                                  max={9}
                                />
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  inputmode="numeric"
                                  className="form-control  custom-form-control"
                                  placeholder="0"
                                  name="ssn-4"
                                  value={ssn_4}
                                  maxLength={1}
                                  // onChange={handleChange}
                                  onChange={(e) => {
                                    if (e.currentTarget.value.length < 2)
                                      handleChange(e);
                                  }}
                                  autocomplete="one-time-code"
                                  max={9}
                                />
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  inputmode="numeric"
                                  className="form-control  custom-form-control"
                                  placeholder="0"
                                  name="ssn-5"
                                  value={ssn_5}
                                  maxLength={1}
                                  // onChange={handleChange}
                                  onChange={(e) => {
                                    if (e.currentTarget.value.length < 2)
                                      handleChange(e);
                                  }}
                                  autocomplete="one-time-code"
                                  max={9}
                                />
                                <input
                                  type="number"
                                  pattern="[0-9]*"
                                  inputmode="numeric"
                                  className="form-control  custom-form-control"
                                  placeholder="0"
                                  name="ssn-6"
                                  value={ssn_6}
                                  maxLength={1}
                                  max={9}
                                  // onChange={handleChange}
                                  onChange={(e) => {
                                    if (e.currentTarget.value.length < 2)
                                      handleChange(e);
                                  }}
                                  autocomplete="one-time-code"
                                />
                              </>}
                              {otpError && otpError != "**" && <span className={isAndroid ? "error-circle-dob-mobile" : "error-circle-dob"}><img src={cross} height={13} width={13} /></span>}
                              {!otpError && <span className={isAndroid ? "error-circle-dob-mobile" : "error-circle-dob"}><img src={tick} height={13} width={13} /></span>}
                            </div>
                          </div>
                        </motion.div>
                        <motion.div
                          className="button_div  insurance_inner_main_btn mt-3 g-0"
                          id="cc-info-div-smbt"
                          initial={{ x: 300 }}
                          animate={{ x: 0 }}
                          transition={{ duration: 0.7 }}
                        >
                          <motion.div className="margin-right "
                          // initial={{ x: 300, opacity: 0 }}
                          // animate={{ x: 0, opacity: 1 }}
                          // transition={{ duration: 12, delay: 0.25 }}
                          >
                            <MotionButton>
                              <button
                                className="btn_success"
                                type="button"
                                onClick={() => validateOtp()}
                              >
                                Submit
                              </button>
                            </MotionButton>
                          </motion.div>
                          <div className="margin-right ">
                            <MotionButton>
                              <button
                                className="btn_default"
                                type="button"
                                onClick={() => resendOtp()}
                              >
                                Resend Code
                              </button>
                            </MotionButton>
                          </div>
                        </motion.div>
                      </motion.div>
                      <motion.div
                        className="button_div  insurance_inner_main_btn2 g-0"
                        id="cc-info-div-smbt"
                        initial={{ x: 300 }}
                        animate={{ x: 0 }}
                        transition={{ duration: 0.7 }}
                      >
                        <div className="margin-right ">
                          <MotionButton>
                            <button
                              className="btn_success"
                              type="button"
                              onClick={() => validateOtp()}
                            >
                              Submit
                            </button>
                          </MotionButton>
                        </div>
                        <div className="margin-right ">
                          <MotionButton>
                            <button
                              className="btn_default"
                              type="button"
                              onClick={() => resendOtp()}
                            >
                              Resend Code
                            </button>
                          </MotionButton>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                </form>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="mobileView">
          <div className="row g-0">
            <div className="col-12 col-sm-12 col-md-0 col-lg-0 bottom">
              <div className="dot-main">
                <span className="dot dotActive"></span>
                <span className="dot"></span>
                <span className="dot "></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NumberVerification;