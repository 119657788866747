import { SearchPatient } from "./SearchPatient"
import { AppPagination, AppTable } from "../../../../components/Table"
import { usePrescriptionColumns } from "../hooks/usePrescriptionColumns"
import { useEffect, useState } from "react"
import { ViewPatientModal } from "../../Modals/ViewPatientModal/ViewPatientModal"
import { POPOVER_CONTENT, AWAITING_FAX_COLUMNS } from "../content/awaitingFaxColumns"
import { useNotesData } from "../../../../utilities/hooks/useAddNotes"
import { NotesModal } from "../../Modals/NotesModal/NotesModal"


export const AwaitingFaxPatients = ({
  data = [],
  onQueryChange,
  pageNo,
  limit,
  total,
  isDataLoading,
}) => {
  const { columns, initiateColumnAction } = usePrescriptionColumns(POPOVER_CONTENT, AWAITING_FAX_COLUMNS);
  const [isViewPatientModalVisible, setIsViewPatientModalVisible] = useState(undefined);
  const [isNotesModalVisible, setNotesModalVisible] = useState(undefined);

  const { notesData, getNotesLoading } = useNotesData(data, isNotesModalVisible);


  useEffect(() => {
    if (!initiateColumnAction.status || !initiateColumnAction.record) return;
    if (initiateColumnAction.status === 'view') setIsViewPatientModalVisible(initiateColumnAction.record);
    if (initiateColumnAction.status === 'notes') setNotesModalVisible(initiateColumnAction.record);

  }, [initiateColumnAction])


  return (
    <>
      <SearchPatient
        onSearchChange={(value) => onQueryChange(value, 'search')}
      />
      {notesData.length > 0 ? (
        <div className="table-container">
          <div className="table-container-inner">
            <AppTable
              columns={columns}
              dataSource={notesData ?? []}
              onChange={(pagination, filter, sort) =>
                onQueryChange(sort, 'sort')
              }
              pagination={false}
              dataLoading={isDataLoading || getNotesLoading}
            />
            <AppPagination
              current={pageNo ?? 1}
              pageSize={limit ?? 10}
              total={total ?? 0}
              onChange={(page, pageSize) =>
                onQueryChange({ page, pageSize }, 'page')
              }
            />
          </div>
        </div>
      ) : (
        <div className="no-data-message-container">
          <span className="no-data-message">
            No transfers are currently queued up
          </span>
        </div>
      )}


      <ViewPatientModal
        visible={isViewPatientModalVisible}
        setVisible={setIsViewPatientModalVisible}
        onSuccessfulSubmit={() => onQueryChange({}, 'refresh')}
      />
      <NotesModal
        visible={isNotesModalVisible}
        setVisible={setNotesModalVisible}
        onSuccessfulSubmit={() => onQueryChange({}, 'refresh')} />
    </>
  );
}