import { INPROCESS_ACTIONS } from "../content/PrescriptionConstants"
import verticalEllipsesImage from "../../../../assets/images/RxLink assets/new assets/vertical-ellipses.png"
import { Popover } from "antd"
import { useState } from "react"
import { checkInsurance } from "../../../../utilities/checkInsurance";

export const PrescriptionTablePopover = ({ setColumnAction, record, patientYes, addInsurance, completeCall, notes, faxReceived, okTransfer, rejectTransfer }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);


  return (
    <Popover
      placement="left"
      arrow={false}
      trigger="click" // or focus or click
      open={popoverVisible}
      onOpenChange={(open) => setPopoverVisible(open)}
      content={
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span
            className="hover-span"
            onClick={() => {
              setColumnAction({ record, status: 'view' })
              setPopoverVisible(false)
            }}
          >
            View
          </span>
          {
            completeCall && (
              <span
                className="hover-span"
                onClick={() => {
                  setColumnAction({ record, status: 'completeCall' })
                  setPopoverVisible(false)
                }}
              >
                Complete Call
              </span>
            )
          }
          {
            faxReceived && (
              <span
                className="hover-span"
                onClick={() => {
                  setColumnAction({ record, status: 'faxReceived' })
                  setPopoverVisible(false)
                }}
              >
                Fax Received
              </span>
            )
          }
          {
            patientYes && (
              <span
                className="hover-span"
                onClick={() => {
                  setColumnAction({ record, status: 'patientYes' })
                  setPopoverVisible(false)
                }}
              >
                Patient Yes
              </span>
            )
          }
          {
            addInsurance && (
              <span
                className="hover-span"
                onClick={!checkInsurance(record) ? () => {
                  setColumnAction({ record, status: 'addInsurance' })
                  setPopoverVisible(false)
                } : null}
              >
                Add Insurance
              </span>
            )
          }
          {
            okTransfer && (
              <span
                className="hover-span"
                onClick={() => {
                  setColumnAction({ record, status: 'okToTransfer' })
                  setPopoverVisible(false)
                }}
              >
                OK To Transfer
              </span>
            )
          }
          {
            rejectTransfer && (
              <span
                className="hover-span"
                onClick={() => {
                  setColumnAction({ record, status: 'cannotTransfer' })
                  setPopoverVisible(false)
                }}
              >
                Can't Transfer
              </span>
            )
          }
          {
            notes && (
              <span
              className="hover-span"
                style={{ color: record?.isRead === 0 && 'red' }}
                onClick={() => {
                  setColumnAction({ record, status: 'notes' })
                  setPopoverVisible(false)
                }}
              >
                Notes
              </span>
            )
          }
        </div>
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
      >
        <img
          src={verticalEllipsesImage}
          alt={'ellipses'}
          width={'5px'}
          height={'18px'}
        />
      </div>
    </Popover>
  )
}