import Login from "./pages/unrestricted/Login";
import { useEffect } from "react";
import {
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NumberVerification from "./pages/unrestricted/NumberVerification";
import PerscriptionsTransfers from "./pages/restricted/PercriptionsTransfers/PercriptionsTransfers";
import ForgotTempPassword from "./pages/unrestricted/forgotTempPassword";
import { successToast, errorToast } from "./components/toast/toast";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setResetPwdModal } from "./reducers/medication";
import { resetPassword } from "./reducers/resetPassword";
import { StringToHTML } from "./utilities/StringToHtml";
import { useGetUser } from "./utilities/useGetUser";

const routes = ['prescriptionTransfers'];

export function RoutesWithAnimation({ auth, setAuth, ownPosition, setOwnPosition, setIp }) {
  var userDetail = useGetUser();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const showResetPwdModal = useSelector((state) => state.medication.isOpenResetPasswordModal);

  const closeChangePasswordModal = () => {
    dispatch(setResetPwdModal(false));
    document.body.style.overflow = 'auto';
  }

  const submitResetPassword = (submitResetPasswordData) => {
    if (submitResetPasswordData?.password) {
      let resetPwd = {
        appUserSeqNo: userDetail?.appUserSeqNo,
        username: userDetail?.username,
        password: submitResetPasswordData?.password,
        forgetPassword: submitResetPasswordData?.tempPassword ? true : false,
        tempPassword: submitResetPasswordData?.tempPassword
          ? submitResetPasswordData?.tempPassword
          : null,
      };
      if (resetPwd?.password) {
        setLoading(true);
        dispatch(resetPassword(resetPwd))
          .unwrap()
          .then((response) => {
            setLoading(false);
            if (response?.statusCode >= 200 && response?.statusCode <= 299) {
              closeChangePasswordModal()
              successToast(response?.message);
              userDetail = JSON.parse(localStorage.getItem("user"));
              localStorage.setItem("user", JSON.stringify({ ...userDetail, token: response.data.token, refreshToken: response.data.refreshToken }));
            } else {
              if (response?.statusCode === 401) {
                errorToast("Old password is invalid.");
              } else {
                errorToast(<StringToHTML htmlString={response?.message} />);
              }
            }
          })
          .catch((e) => {
            setLoading(false);
          });
      }
    }
  }

  const location = useLocation();

  useEffect(()=>{
    if (!userDetail && routes.includes(location.pathname)) {
      navigate("/")
  }
  },[location.pathname, userDetail])

  return (
    <>
      {
        loading ? (
          <div className="loader"></div>
        ) : (
          <>
            <Routes key={location.pathname} location={location}>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/otpVerify" element={<NumberVerification setIp={setIp} />} />
              <Route exact path="/prescriptionTransfers" element={<PerscriptionsTransfers />} />
            </Routes>

            <ForgotTempPassword
              show={showResetPwdModal}
              close={() => closeChangePasswordModal()}
              setSubmitResetPasswordData={submitResetPassword}
              isOldPassword={true}
            />
          </>
        )}
    </>
  );
}