import { motion } from "framer-motion"

export const MotionButton = ({ children }) => {
    return (
        <motion.div
            transition={{ type: "tween" }}
            whileTap={{
                scale: 0.95,
                transition: { duration: 0.1 },
            }}
        >
            {children}
        </motion.div>
    )
}

export const MotionIcon = ({ children }) => {
    return (
        <motion.div
            transition={{ type: "tween" }}
            whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
        >
            {children}
        </motion.div>
    )
}