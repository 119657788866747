import { useState, useEffect, useRef } from "react";

export const SearchPatient = ({ onSearchChange }) => {
    const [search, setSearch] = useState('')
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        const timer = setTimeout(() => {
            onSearchChange && onSearchChange(search)
        }, 500)

        return () => clearTimeout(timer)
    }, [search])

    return (
        <div className={'col-12 col-sm-12 col-md-12 col-lg-12'}>
            <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search for a patient's name or phone"
                className="search-bar autoMargin medication-search-margin"
            />
        </div>
    );
}