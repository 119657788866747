import React from 'react';
import { Modal } from 'antd';
import { motion } from 'framer-motion';
import cancelIcon from "./../assets/images/RxLink assets/new assets/regular x.svg"
import { HDivider } from './Divider';
import { MotionButton } from './motion/MotionButton';
import { Button } from './Button';

export const ModalWrapper = ({
  title,
  content,
  onOk,
  visible,
  setVisible,
  closeButton,
  submitButtonText = 'Save',
  modelHidden,
  isSubmitLoading,
  isCancelLoading,
  onCancel,
  className,
  hiddenScrollbar,
  saveButton, 
  saveButtonVarient = "primary",
  cancelButtonVarient= "secondary",
  cancelButtonText="Cancel",
  rejectHandler,
}) => {
  const handleOk = async () => {
    onOk();
  };

  const handleCancel = () => {
    if (isSubmitLoading || isCancelLoading) return;
    if (!onCancel) return setVisible(undefined);
    if(rejectHandler) rejectHandler();
    onCancel();
  };

  return (
    <>
      {visible && (
        <Modal
          style={{ display: modelHidden ? 'none' : 'block' }}
          className={'antd-modal-width antd-modal ' + className}
          title={<span className="antd-modal-title">{title}</span>}
          open={visible}
          onOk={handleOk}
          footer={
            saveButton ? <div className="ant-modal-footer-actions">
              <Button
                variant={saveButtonVarient}
                onClick={handleOk}
                loading={isSubmitLoading}
              >
                {submitButtonText}
              </Button>
              {closeButton && (
                <Button
                  onClick={handleCancel}
                  variant={cancelButtonVarient}
                  disabled={isSubmitLoading}
                  loading={isCancelLoading}
                >
                  {cancelButtonText}
                </Button>
              )}
            </div> : false
          }
          width={645}
          closeIcon={
            <div className="row x" style={{ marginTop: '-5px' }}>
              <motion.button
                transition={{ type: 'tween' }}
                whileTap={{
                  scale: 0.95,
                  transition: { duration: 0.1 },
                }}
                className="crossButton"
                onClick={handleCancel}
              >
                <img src={cancelIcon} alt="close popup" />
              </motion.button>
            </div>
          }
        >
          <HDivider />
          <div className={`modal-content ${hiddenScrollbar && 'hide-scroll'}`}>
            {content}
          </div>
        </Modal>
      )}
    </>
  );
};
