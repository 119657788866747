import { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../reducers/auth";
import { resetPassword } from "../../reducers/resetPassword";
import { forgetPwd } from "../../reducers/forgotPassword";
import ResetPassword from "./ResetPassword";
import ForgetPassword from "./ForgetPassword";
import ForgotTempPassword from "./forgotTempPassword";
import { successToast, errorToast } from "../../components/toast/toast"
import "react-toastify/dist/ReactToastify.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { MotionText } from "../../components/motion/MotionText";
import 'react-datepicker/dist/react-datepicker.css';
import {
  generateOtp,
} from "../../reducers/validateOtp";
import { setShowResetPasswordModal } from "../../reducers/medication";
import { UsernameField } from "../../components/Fields/Username";
import { setIP, setAuth } from "../../reducers/global";
import { getDomainName } from "../../utilities/useGetDomain";
import LCMCIcon from "../../assets/images/LCMC_Health_Logo.png";
import { VDivider } from "../../components/Divider";
import { Button } from "../../components/Button";
import { usePostMutation } from "../../utilities/API/javaAPIClient/api";
import { URLS } from "../../utilities/API/javaAPIClient/constants";
import {StringToHTML} from "../../utilities/StringToHtml";

const Login = () => {
  var userDetail = JSON.parse(localStorage.getItem("user"));
  const [isOpenForgotPwdModal, setIsOpenForgotPwdModal] = useState(false);
  const [resetPwd, setResetPasswd] = useState("");
  const [isTempReset, setIsTempReset] = useState(false);
  const [submitResetPasswordData, setSubmitResetPasswordData] = useState("");
  const [submitForgotPasswordData, setSubmitForgotPasswordData] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const [validateEmail, setValidateEmail] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading: loginLoading, mutateAsync: loginMutation } = usePostMutation();

  const { domainName, defaultLogo } = getDomainName();

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Email is required")
      .email("Invalid email"),

    password: Yup.string()
      .required("Password is required")
  });

  const showResetPasswordModal = useSelector((state) => state.medication.showResetPasswordModal);
  const pickerRef = useRef(null)

  const queryParameters = new URLSearchParams(window.location.search);
  const isUpenn = window.location.href.includes("upenn");
  const isPapaJohn = window.location.href.includes("papajohns") || window.location.href.includes("pju");
  const isTripment = window.location.href.includes("tripment");
  const isHouston = window.location.href.includes("hhc");
  const isLcmc = window.location.href.includes("lcmc");

  const ctrls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      ctrls.start("visible");
    }
    if (!inView) {
      ctrls.start("hidden");
    }
  }, [ctrls, inView]);

  const wordAnimation = {
    hidden: {},
    visible: {},
  };

  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `0.25em`,
    },
    visible: {
      opacity: 1,
      y: `0em`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };

  const formik = useFormik({
    initialValues: {
      "username": "",
      "password": "",
      "domainName": "LCMC_Pharmacist"
    },
    validationSchema: validationSchema,
    onSubmit: (data, { resetForm, setFieldValue }) => {
      try {
        loginMutation({ url: URLS.login, data: data }).then((response) => {
          if (!response) return;

          if (response?.data) {
            localStorage.setItem("user", JSON.stringify(response?.data));
            if (response?.data?.enrollmentStatus === 'Unverified') {
              dispatch(generateOtp(data)).unwrap().then((result) => {

                if (result?.data) {
                  localStorage.setItem("user", JSON.stringify(response?.data));
                  successToast(result?.message);
                  navigate("/otpVerify");
                  dispatch(setIP(response?.data.proxyIpAddress));
                }
              })
            }
            else {
              if ((response?.statusCode >= 200 && response?.statusCode <= 299) || response?.statusCode === 307) {
                dispatch(setAuth(response?.data));
                navigate("/prescriptionTransfers");
              }
            }
          }
          else {
            errorToast(<StringToHTML htmlString={response?.message} />);
          }
        });

      } catch (error) {
        console.log({ error })
      }
    },
  });

  useEffect(() => {
    if (submitForgotPasswordData?.number) {
      setLoading(true);
      dispatch(
        forgetPwd(
          submitForgotPasswordData?.number
        )
      )
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.status === "OK") {
            localStorage.setItem("user", JSON.stringify(response?.data));
            userDetail = response?.data;
            successToast(response?.message);
            setIsOpenForgotPwdModal(false);
            setIsTempReset(true);
          } else {
            errorToast(response?.message);
            setLoading(false);
          }
        });
    }
  }, [submitForgotPasswordData]);

  useEffect(() => {
    if (submitResetPasswordData?.password) {
      let body = {
        appUserSeqNo: userDetail?.appUserSeqNo,
        username: userDetail?.username,
        password: submitResetPasswordData?.password,
        forgetPassword: submitResetPasswordData?.tempPassword ? true : false,
        tempPassword: submitResetPasswordData?.tempPassword
          ? submitResetPasswordData?.tempPassword
          : null,
      };
      setResetPasswd(body);
    }
  }, [submitResetPasswordData]);


  useEffect(() => {
    if (resetPwd?.password) {
      setLoading(true);
      dispatch(resetPassword(resetPwd))
        .unwrap()
        .then((response) => {
          setLoading(false);
          if (response?.statusCode >= 200 && response?.statusCode <= 299) {
            dispatch(setShowResetPasswordModal(false));
            successToast(response?.message);
            setIsTempReset(false);
            userDetail = JSON.parse(localStorage.getItem('user'));
            // window.location.replace('/#/prescriptionTransfers');
          } else {
            errorToast(response?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [resetPwd]);

  const changePasswordType = () => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  const text = 'Prescription Transfer Management Console';

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          <motion.section className=""
            // variants={loginRouteVariants}
            // initial="initial"
            // animate="final"
            initial={{ y: "-100vh" }}
            animate={{ y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="login_section">
              <motion.form onSubmit={(e) => {
                setValidateEmail(true)
                formik.handleSubmit(e)
              }
              } id="loginID"
              >
                <div className="row g-0">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="login_section_inner">
                      <div className="row g-0">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="logo">
                            <motion.img src={defaultLogo} className="img-fluid"
                              initial={{ y: -100 }}
                              animate={{ y: 0 }}
                              transition={{ duration: 0.5, delay: 0.4 }}
                            />
                            <VDivider />
                            <motion.img src={LCMCIcon} className="img-fluid"
                              initial={{ y: -100 }}
                              animate={{ y: 0 }}
                              transition={{ duration: 0.5, delay: 0.4 }}
                              style={{paddingLeft: "10px"}}
                            />
                          </div>
                          <h3 style={{width: 300}}>{text}</h3>
                        </div>
                      </div>
                      <UsernameField
                        formik={formik}
                        validateSubmit={validateEmail}
                      />
                      <div className="row g-0">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="forget_input_eye mb-1  form-group">
                            <input
                              name="password"
                              type={passwordType}
                              className={
                                "form-control custom-form-control" +
                                (formik.errors.password &&
                                  formik.touched.password
                                  ? " is-invalid"
                                  : "")
                              }
                              id="floatingInput"
                              placeholder="Password"
                              onChange={formik.handleChange}
                              value={formik.values.password}
                            />
                            <div className="invalid-feedback">
                              {formik.errors.password && formik.touched.password
                                ? formik.errors.password
                                : null}
                            </div>
                            <i
                              className={passwordType == "password" ? "fa fa-eye" + " pointer" : "fa fa-eye-slash" + " pointer"}
                              onClick={() => changePasswordType()}
                              style={{ top: 13, color: "#77aca2" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div className="row g-0">
                        <Button
                          variant='primary'
                          type='submit'
                          form="loginID"
                          loading={loginLoading}
                        >
                          Submit
                        </Button>
                      </div>
                      <div className="row g-0 mt-1">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <p
                            className="password pointer m-0"
                            onClick={() => !loginLoading && setIsOpenForgotPwdModal(true)}
                          >
                            <a>I don't have a password</a>
                          </p>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.form>
            </div>
          </motion.section>

          <ForgetPassword
            show={isOpenForgotPwdModal}
            close={() => setIsOpenForgotPwdModal(false)}
            setSubmitForgotPasswordData={setSubmitForgotPasswordData}
          />

          <ResetPassword
            show={showResetPasswordModal}
            close={() => {
              dispatch(setShowResetPasswordModal(false));
            }}
            setSubmitResetPasswordData={setSubmitResetPasswordData}
          />

          <ForgotTempPassword
            show={isTempReset}
            close={() => setIsTempReset(false)}
            setSubmitResetPasswordData={setSubmitResetPasswordData}
          />
        </>
      )}
    </>
  );
};

export default Login;