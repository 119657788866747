import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
    memberInfo: null,
    smsOrEmailResponse: {}
};

export const sndSMSOrEmail = createAsyncThunk("sendSMSOrEmail", (body) =>
    httpClientJava
        .post(`medication/sendSMSOrEmail`, body)
        .then(({ data }) => {
            return data;
        })
        .catch(error => error)
);


export const sendSMSOrEmailAPI = createSlice({
    name: "sendSMSOrEmail",
    initialState,
    // reducers: {

    // },
    reducers: {
        setSMSOrEmailResponse: (state, action) => {
            state.smsResponse = action.payload;
            state.memberInfo = action.payload;
        },
        [sndSMSOrEmail.pending.type]: (state, action) => {
            state.assignMembersData = {
                status: "loading",
                data: {},
                error: {},
            };
        },
        [sndSMSOrEmail.fulfilled.type]: (state, action) => {
        //console.log(action.payload);
            state.assignMembersData = {
                status: "idle",
                data: action.payload,
                error: {},
            };
        },
        [sndSMSOrEmail.rejected.type]: (state, action) => {
            state.assignMembersData = {
                status: "idle",
                data: {},
                error: action.payload,
            };
        },
    },
});

export const { setSMSOrEmailResponse } = sendSMSOrEmailAPI.actions;

export default sendSMSOrEmailAPI.reducer;