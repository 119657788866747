import { convertDateStringToFormat } from "../../../../../utilities/date/date"
import { INPROCESS_STATES, INPROCESS_STATUS } from "../../../PercriptionsTransfers/content/PrescriptionConstants"
import { HISTORY_STYLES, STATUS_STEPS } from "./content"


export const Icon = ({ src, alt }) => <img src={src} alt={alt} width={24} height={24} />

export const ImageInfo = ({ src, alt, text }) => <div className='inner-info'>
    <Icon src={src} alt={alt} />
    <span style={{margineft: 5}}>{text}</span>
</div>

export const TextInfo = ({ infoText, text }) => <div className='inner-info'>
    <span style={{ fontWeight: 600, marginRight: 5 }}>{infoText}</span>
    <span>{text}</span>
</div>

export const HistoryInfo = ({ statusText, currentStep, index, history }) => {
    const currentIndex = index + 1
    const historyStyle = currentIndex === currentStep ? HISTORY_STYLES.current : currentIndex < currentStep ? HISTORY_STYLES.previous : HISTORY_STYLES.next
    const isPreviousStep = currentIndex < currentStep
    const lastStep = STATUS_STEPS.length
    const currentStatus = INPROCESS_STATES[index + 1]
    const date = history.find(
      (item) => item.transferStatus === currentStatus,
    )?.processingDate;

    return (
        <div style={{ display: 'flex', gap: '15px', justifyContent: 'flex-start', alignItems: 'center', position: 'relative' }}>
            {currentIndex !== lastStep && <div className='status-flow-line' />}
            <div className={historyStyle.icon} style={{ marginLeft: historyStyle.marginLeft }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <span style={{ ...historyStyle.style, marginLeft: historyStyle.marginLeft }}>{statusText}</span>
                {
                    isPreviousStep && date && <span style={{ fontSize: '11px', color: '#0000008F' }}>{convertDateStringToFormat(date, 'MMM DD')}</span>
                }
            </div>
        </div>)
}
