
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MotionButton } from "../../components/motion/MotionButton";
import { UsernameField, usernameValidation } from "../../components/Fields/Username";
import { useRef } from "react";

const ForgetPassword = (props) => {
  const [mask, setMask] = useState("")
  const [modalIsOpen, setModalIsOpen] = useState(props.show);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [isOpenResetPwdModal, setIsOpenResetPwdModal] = useState(false);
  var numberPattern = /\d+/g;
  const userNameRef = useRef(null);
  useEffect(() => {
    // console.log(modalIsOpen);
  }, [modalIsOpen]);

  const changePasswordType = () => {
    setPasswordType(passwordType == "password" ? "text" : "password");
  };

  const changeConfirmPasswordType = () => {
    setConfirmPasswordType(
      confirmPasswordType == "password" ? "text" : "password"
    );
  };

  const validationSchema = Yup.object().shape({
    username: usernameValidation,
  });

  const formik = useFormik({
    initialValues: {
      username: '',

    },
    validationSchema,
    // validateOnChange: false,
    // validateOnBlur: false,
    onSubmit: (data) => {
      let body = {
        number: mask ? data.username.match(numberPattern).join("") : data.username,
      };
      props.setSubmitForgotPasswordData(body);
    },
  });

  const setResetPwd = (value) => {
    setIsOpenResetPwdModal(value);
  }

  const { isSubmitting, isValidating, resetForm } = formik;

  // useEffect(() => {
  //   if (isSubmitting && isValidating) {
  //     if (Object.keys(formik.errors).length > 0) {
  //       const firstErrorField = Object.keys(formik.errors).find(
  //         (fieldName) => formik.touched[fieldName]
  //       );
  //       if (firstErrorField) {
  //         if (firstErrorField === "username") {
  //           window.document.getElementById("forgotPasswordPhoneInput").focus();
  //         }
  //       }
  //     }
  //   }
  // }, [isSubmitting, isValidating])

  useEffect(() => {
    if (!props.show) {
      resetForm();
      setMask("");
    }
  }, [props.show])


  const resetFormFields = () => {
    resetForm();
    setMask("");
  };


  return (
    <>
      <Modal className="hide-scrollbar" show={props.show} onHide={props.close} keyboard={false} centered>
        {/* <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header> */}
        <Modal.Body>
          <section className="forget_section">
            <div className="container">
              <form onSubmit={formik.handleSubmit} id="resetPasswordForm1">
                <div className="forget_box" >
                  {/* <div className="row x">
                    <div className="text-end">
                      <button
                        className="crossButton"
                        onClick={props.close}
                        type="button"
                      >
                        <img src={cancelIcon} />
                      </button>
                    </div>
                  </div> */}
                  <div role="alert" className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <p >
                        Forgot Password
                      </p>
                    </div>
                  </div>
                  <UsernameField
                    formik={formik}
                    mask={mask}
                    setMask={setMask}
                  />

                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <MotionButton>
                        <button
                          type="submit"
                          className="btn_success"
                          form="resetPasswordForm1" onClick={() => setResetPwd(true)}
                        >
                          Submit
                        </button>
                      </MotionButton>
                      <div className="col-sm-12 col-md-12 col-lg-12">
                        <MotionButton>
                          <button
                            type="button"
                            className="btn_default mt-2"
                            onClick={() => {
                              resetFormFields()
                              props.close()
                            }}
                          >
                            Cancel
                          </button>
                        </MotionButton>
                      </div>
                    </div>
                  </div>
                  <div className="row">

                  </div>
                </div>
              </form>
            </div>
          </section>
        </Modal.Body>
        {/* <Modal.Footer>
                    <Button variant="secondary" >
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ForgetPassword;
