import { ModalWrapper } from "../../../../components/Modal";
import { useFormik } from "formik";
import * as Yup from "yup"
import NotesList from "./component/NotesList";
import { URLS } from "../../../../utilities/API/javaAPIClient/constants";
import { usePostMutation } from "../../../../utilities/API/javaAPIClient/api";
import { successToast, errorToast } from "../../../../components/toast/toast";

export const NotesModal = ({ visible, setVisible, onSuccessfulSubmit }) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const { isLoading: postNoteLoading, mutateAsync: postNote } = usePostMutation();

    const onCancel = () => {
        formik.setFieldValue('addNote', "")
        setVisible(undefined)
    }

    const validationSchema = Yup.object().shape({
        addNote: Yup.string().required(),
    });

    const formik = useFormik({
        initialValues: {
            addNote: "",
        },
        validationSchema,
        onSubmit: (data) => {
            let body = {
                note: data.addNote,
                from: user ? user?.domainName ?? '' : '',
                appUserSeqNo: user ? user.appUserSeqNo ?? 0 : 0,
                userSeqNo: visible.userSeqNo ?? 0,
                firstName: visible.firstName ?? '',
                lastName: visible.lastName ?? '',
                rxDate: visible.rxDate ?? '',
                pharmacyName: visible.pharmacyName ?? '',
            };
            console.log({ body })
            try {
                postNote({ url: URLS.addNote, data: body }).then((response) => {
                    console.log({ addedNote: response })
                    if (!response) return;
                    if (response.statusCode === 200) {
                        successToast("Note added successfully");
                        setVisible(undefined);
                        onSuccessfulSubmit();
                        formik.setFieldValue('addNote', "")

                    } else {
                        errorToast(response.message);
                        setVisible(undefined);
                        formik.setFieldValue('addNote', "")

                    }
                });
            } catch (error) {
                console.log({ error })
                formik.setFieldValue('addNote', "")

            }

        },
    });
    return (
        <ModalWrapper
            title={"Notes"}
            modelHidden={false}
            visible={visible}
            setVisible={setVisible}
            saveButton={true}
            onOk={formik.handleSubmit}
            onCancel={onCancel}
            // hiddenScrollbar={true}
            // isSubmitLoading={isSubmitLoading}
            // isCancelLoading={isCancelLoading}
            submitButtonText={"Save"}
            content={
                <>
                    <div className="container" style={{ maxWidth: "100%", padding: 2 }}>
                        <NotesList formik={formik} notes={visible?.notes} />
                    </div>
                </>
            }
        />
    )
}