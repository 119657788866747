import { ImageInfo } from "./Component"
import personIcon from '../../../../../assets/images/RxLink assets/Icons/Icon_person_filled.png';
import cakeIcon from '../../../../../assets/images/RxLink assets/Icons/Icon_cake_full.png';
import pinIcon from '../../../../../assets/images/RxLink assets/Icons/Icon_pin_filled.png';
import phoneIcon from '../../../../../assets/images/RxLink assets/Icons/Icon_phone_filled.png';
import faxIcon from '../../../../../assets/images/RxLink assets/Icons/Icon_fax_filled.png';
import shopIcon from '../../../../../assets/images/RxLink assets/Icons/Icon_shop_filled.png';
import { formatPhoneNumber } from "../../../../../utilities/formatters/formatPhoneNumber";
import { TextInfo } from "./Component";

export const PatientInfo = ({ patientName, dob, phone, address }) => {
    const phoneNumber = formatPhoneNumber(phone)
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: '10px', fontSize: '11px', fontWeight: 400, color: '000000E5' }}>
            <span style={{ fontWeight: 600 }}>PATIENT</span>
            <div className='patient-pharmacy-info'>
                <ImageInfo src={personIcon} alt="person" text={patientName ?? ''} />
                <ImageInfo src={cakeIcon} alt="dob" text={dob ?? ''} />
                <ImageInfo src={phoneIcon} alt="phone" text={phoneNumber ?? ''} />
                <ImageInfo src={pinIcon} alt="address" text={address ?? ''} />
            </div>
        </div>
    )
}

export const PharmacyInfo = ({ pharmacyName, fax, phone, address, city, state, zipCode }) => {
    const phoneNumber = formatPhoneNumber(phone)
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: '10px', fontSize: '11px', fontWeight: 400, color: '000000E5' }}>
            <span style={{ fontWeight: 600 }}>PHARMACY</span>
            <div className='patient-pharmacy-info'>
                <ImageInfo src={shopIcon} alt="person" text={pharmacyName ?? ''} />
                <ImageInfo src={faxIcon} alt="dob" text={fax ?? ''} />
                <ImageInfo src={phoneIcon} alt="phone" text={phoneNumber ?? ''} />
                <ImageInfo src={pinIcon} alt="address" text={address + ". " + city + ", " + state + ", " + zipCode} />
            </div>
        </div>
    )
}

export const InsuranceInfo = ({ group, pcn, id, bin }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: '10px', fontSize: '11px', fontWeight: 400, color: '000000E5' }}>
            <span style={{ fontWeight: 600 }}>INSURANCE</span>
            <div className='patient-pharmacy-info'>
                <TextInfo infoText="GROUP" text={group ?? ''} />
                <TextInfo infoText="PCN" text={pcn ?? ''} />
                <TextInfo infoText="ID" text={id ?? ''} />
                <TextInfo infoText="BIN" text={bin ?? ''} />
            </div>
        </div>
    )
}

export const OfferLink = (link) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', gap: '10px', fontSize: '11px', fontWeight: 400, color: '000000E5' }}>
            <span style={{ fontWeight: 600 }}>OFFER</span>
            <span>Patient can enroll for savings here: <span style={{ fontWeight: 600, cursor: 'pointer' }}>link.com</span></span>
        </div>
    )
}