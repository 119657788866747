import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClient } from "../environment";
import { useDispatch } from "react-redux";

const initialState = {
    drugs: [],
    filterDrugsArray: []
  };
  
export const searchDrugsByName = createAsyncThunk("searchDrugs", (query) =>
    httpClient
    .get(`search?query=${query}&hitsPerPage=6`) 
    .then(({data}) => {
        return data;
        // const dispatch = useDispatch();
        // dispatch(setDrugsList(data));
    })
    .catch(error => error)
);


export const drugsList = createSlice({
    name: "drugsList",
    initialState,
    // reducers: {
      
    // },
    reducers: {
      setDrugsList: (state, action) => {
        state.drugs = action.payload; //state.drugs.filter((drug) => drug.name.toLowerCase().includes(action.payload)); 
        state.filterDrugsArray = action.payload;
      },
        [searchDrugsByName.pending.type]: (state, action) => {
          state.drugs = {
            status: "loading",
            data: {},
            error: {},
          };
        },
        [searchDrugsByName.fulfilled.type]: (state, action) => {
      //console.log(action.payload);
          state.drugs = {
            status: "idle",
            data: action.payload,
            error: {},
          };
        },
        [searchDrugsByName.rejected.type]: (state, action) => {
          state.drugs = {
            status: "idle",
            data: {},
            error: action.payload,
          };
        },
      },
});

export const { setDrugsList } = drugsList.actions;

export default drugsList.reducer;