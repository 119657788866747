export function useGetUser() {
    const getUserFromLocalStorage = () => {
       const userDetail = localStorage.getItem("user");
       if (!userDetail) {
           return null;
       }
       try {
           const parsedUserDetail = JSON.parse(userDetail);
           return parsedUserDetail;
       } catch (error) {
           console.error("Error parsing user object:", error);
           return null;
       }
   };
   let user = getUserFromLocalStorage();
return user;
}