export const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    if (phoneNumber.length !== 10 || !/^\d+$/.test(phoneNumber)) return phoneNumber;


    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');;
}

export const concateStrings = (stringArray) => {
    const concatedText = stringArray.filter(name => name).join(' ')
    return concatedText ?? ''
}

export const formatDob = (dob) => {
    if (!dob) return '';
    if (dob.length !== 8 || !/^\d+$/.test(dob)) return dob;

    const year = dob.substring(0, 4);
    const month = dob.substring(4, 6);
    const day = dob.substring(6, 8);

    return `${month}.${day}.${year}`;
}