import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpClientJava } from "../environment";

const initialState = {
    username: "",
    otp: "",
};

export const validateOTP = createAsyncThunk("ValidateOtp", (body) =>
    httpClientJava
        .post(`validateOtp`, body)
        .then(({ data }) => {
            return data;
        })
        .catch(error => error)
);

export const generateOtp = createAsyncThunk("generateOtp", (body) =>
    httpClientJava
        .get(`generateOtp?username=` + body?.username)
        .then(({ data }) => {
            return data;
        })
        .catch(error => error)
);

export const otpExpiredApi = createAsyncThunk("otpExpired", (body) =>
    httpClientJava
        .post(`otpExpired` + body?.username)
        .then(({ data }) => {
            return data;
        })
        .catch(error => error)
);

export const validateOtpAPI = createSlice({
    name: "validateOtp",
    initialState,
    reducers: {

    },
});

export const { setSMSOrEmailResponse } = validateOtpAPI.actions;

export default validateOtpAPI.reducer;