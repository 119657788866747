import { useRef, useEffect } from "react";

export const useAfterRenderEffect = (effect, dependencies) => {
    const isInitialRender = useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            return effect();
        }
    }, dependencies);

};