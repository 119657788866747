import { MotionButton } from "../../../../components/motion/MotionButton"
import { INPROCESS_ACTIONS, INPROCESS_STATUS } from "./PrescriptionConstants"
import { PrescriptionTablePopover } from '../components/PrescriptionTablePopover';
import { INPROCESS_STATUS_CONTENT } from './PrescriptionConstants';
import { Tooltip } from '../../../../components/Tooltip';
import { convertDateStringToFormat } from '../../../../utilities/date/date';
import { checkInsurance } from "../../../../utilities/checkInsurance";
import { formatPhoneNumber } from "../../../../utilities/formatters/formatPhoneNumber";

export const TRANSFER_FOR_APPROVAL_COLUMNS = (setColumnAction) => [
  {
    title: 'PATIENT',
    key: 'patient',
    render: (record) => {
      const patientName = `${record.firstName ?? ''}${record.firstName ? ' ' : ''
        }${record.lastName ?? ''}`;
      return <Tooltip text={patientName} />;
    },
  },
  {
    title: 'MEDICATION',
    key: 'medName',
    sorter: true,
    render: (record) => {
      return <Tooltip text={record.medName} />;
    },
  },
  {
    title: 'RX DATE',
    key: 'rxDate',
    sorter: true,
    render: (record) => convertDateStringToFormat(record.rxDate),
  },
  {
    title: 'GROUP',
    key: 'groupNumber',
    sorter: true,
    render: (record) => {
      return <Tooltip text={record?.groupNumber} />;
    },
  },
  {
    title: 'BIN',
    key: 'bin',
    sorter: true,
    render: (record) => {
      return <Tooltip text={record?.bin} />
    },
  },
  {
    title: 'PCN',
    key: 'pcn',
    sorter: true,
    render: (record) => {
      return <Tooltip text={record?.pcn} />
    },
  },
  {
    title: 'ID',
    key: 'memberId',
    sorter: true,
    render: (record) => {
      return <Tooltip text={record?.memberId} />
    },
  },
  {
    key: 'action_buttons',
    render: (record) => {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '10px',
            justifyContent: 'end',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <MotionButton>
            <button
              className="btn_default"
              style={{ marginTop: '0px' }}
              onClick={() => setColumnAction({ record, status: 'view' })}
            >
              View
            </button>
          </MotionButton>
          <MotionButton>
            <button
              className="btn_success"
              style={{ marginTop: '0px' }}
              onClick={() =>
                setColumnAction({ record, status: 'okToTransfer' })
              }
            >
              OK To Transfer
            </button>
          </MotionButton>
          <MotionButton>
            <button
              className={"btn_default"}
              style={{ marginTop: '0px' }}
              onClick={() =>
                setColumnAction({ record, status: 'cannotTransfer' })
              }
            >
              Can't Transfer
            </button>
          </MotionButton>
          <MotionButton>
            <button
              className="btn_success"
              style={{ marginTop: '0px', color: record.isRead === 0 && "red" }}
              onClick={() =>
                setColumnAction({ record, status: 'notes' })
              }
            >
              Notes
            </button>
          </MotionButton>
        </div>
      );
    },
  },
];

export const POPOVER_CONTENT = (setColumnAction) => ({
  key: 'action_popover',
  fixed: 'right',
  render: (record) => {
    return (
      <PrescriptionTablePopover
        setColumnAction={setColumnAction}
        record={record}
        notes={true}
        okTransfer={true}
        rejectTransfer={true}
      />
    );
  },
});